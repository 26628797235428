import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Vacancies from './Vacancies';
import Vacancy from './Vacancy';
import VacancyApply from './VacancyApply';
import VacancyApplySuccess from './VacancyApplySuccess';
import Unsubscribe from './Unsubscribe';
import { useTelegram } from '../utils/useTelegram';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Vacancies />
    },
    {
        path: '/vacancies/:id',
        element: <Vacancy />
    },
    {
        path: '/vacancies/:id/apply',
        element: <VacancyApply />
    },
    {
        path: '/success',
        element: <VacancyApplySuccess />
    },
    {
        path: '/unsubscribe',
        element: <Unsubscribe />
    }
]);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 0,
            refetchOnWindowFocus: false,
        },
    }
});

const App = () => {
    const { tg } = useTelegram();

    return <QueryClientProvider client={queryClient}>
        <div data-theme={tg.colorScheme === 'dark' ? 'dark' : "cmyk"} className='min-h-screen'>
            <RouterProvider router={router} />
        </div>
    </QueryClientProvider>;
}

export default App;
