import axios from 'axios';

import { BASE_URL } from '../constants/urls';

axios.defaults.timeout = 5 * 60 * 1000;

export function setBaseUrl(base = BASE_URL) {
    axios.defaults.baseURL = base;
}

