import { useQuery } from "react-query";
import axios from "axios";

import * as urls from '../constants/urls';
import { path } from "ramda";

const getVacancies = async (params) => {
    const { data } = await axios.get(urls.VACANCIES.stringify(), { params });
    return data;
};

const getVacancy = async (id) => {
    const { data } = await axios.get(urls.VACANCIES.stringify({ id }), {
        params: {
            relations: ['cities']
        }
    });
    return data;
};

export const postVacancyApplicant = async (payload) => {
    const { data } = await axios.post(urls.VACANCY_APPLICANT.stringify({ id: payload.id }), payload);
    return data;
};

export const useVacancies = (payload, params) => useQuery(
    ['vacancies', path(['filter'], payload), path(['pagination'], payload)],
    () => getVacancies(payload),
    params
);

export const useVacancy = (id, params) => useQuery(
    ['vacancy', id],
    () => getVacancy(id),
    params
);
