import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FindyForm from '@experium/findy-form';

import { useTelegram } from '../utils/useTelegram';

import '@experium/findy-form/lib/styles/index.css';
import { BASE_URL, FILE } from '../constants/urls';
import { useMutation } from 'react-query';
import { postVacancyApplicant, useVacancy } from '../queries/vacancies';
import { useState } from 'react';
import { pathOr } from 'ramda';

const VacancyApply = () => {
    const { tg, showBackButton, hideBackButton, hideMainButton } = useTelegram();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const { data, isFetched } = useVacancy(params.id);
    const postVacancyApplicantMutation = useMutation(postVacancyApplicant, {
        onSuccess: () => {
            setLoading(false);
            navigate('/success');
        },
        onError: () => {
            setLoading(false);
            tg.showAlert('Не удалось отправить отклик');
        }
    });

    useEffect(() => {
        hideMainButton();
        searchParams.get('backbutton') === '1' ? showBackButton() : hideBackButton();
    }, []);

    const onBackButtonClicked = useCallback(() => navigate(-1));

    useEffect(() => {
        tg.onEvent('backButtonClicked', onBackButtonClicked);
        return () => tg.offEvent('backButtonClicked', onBackButtonClicked);
    }, [onBackButtonClicked]);

    const onSubmit = values => {
        setLoading(true);
        postVacancyApplicantMutation.mutate(values);
    }

    return <div className='container mx-auto p-4'>
        { isFetched &&
            <FindyForm
                initialValues={{ id: params.id }}
                fields={pathOr([], ['questions'], data)}
                onSubmit={onSubmit}
                submitting={loading}
                postFileUrl={BASE_URL + FILE.stringify({ vacancy: params.id })}
                getFileUrl={id => BASE_URL + FILE.stringify({ vacancy: params.id, id })} />
        }
    </div>;
}

export default VacancyApply;
