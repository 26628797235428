import React, { useCallback, useEffect } from 'react';
import { useTelegram } from '../utils/useTelegram';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useVacancy } from '../queries/vacancies';
import { pathOr } from 'ramda';
import { HomeModernIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import { useMutation } from 'react-query';
import { postBotSend } from '../queries/bot';

const Vacancy = () => {
    const { tg, showMainButton, showBackButton, hideBackButton, userId } = useTelegram();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const { data, isFetched } = useVacancy(params.id);
    const postBotSendMutation = useMutation(postBotSend, {
        onSuccess: () => {
            tg.showAlert('Вакансия отправлена в чат бот');
        },
        onError: () => {
            tg.showAlert('Не удалось отправить вакансию в чат бот');
        }
    });

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'ОТКЛИКНУТЬСЯ',
            color: '#249EEB'
        });
        searchParams.get('backbutton') === '1' ? showBackButton() : hideBackButton();
        showMainButton();
    }, []);

    const onMainButtonClicked = useCallback(() => {
        navigate(`/vacancies/${params.id}/apply?backbutton=1`);
    }, [params.id]);

    const onBackButtonClicked = useCallback(() => navigate(-1));

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onMainButtonClicked);
        return () => tg.offEvent('mainButtonClicked', onMainButtonClicked);
    }, [onMainButtonClicked]);

    useEffect(() => {
        tg.onEvent('backButtonClicked', onBackButtonClicked);
        return () => tg.offEvent('backButtonClicked', onBackButtonClicked);
    }, [onBackButtonClicked]);

    const sendVacancyInChat = () => {
        postBotSendMutation.mutate({
            userId,
            vacancyId: params.id
        })
    }

    const cities = pathOr([], ['_relations', 'cities'], data);

    return isFetched && <div className='container mx-auto p-4'>
        <h1 className='text-2xl'>{ data.title }</h1>
        <div className='mt-8'>
            { !!cities.length &&
                <div className='flex'><HomeModernIcon className="h-6 w-6 mr-1" /> { cities.reduce((res, cur) => res ? `${res}, ${cur.name}` : cur.name, '') }</div>
            }
            { (data.minSalary || data.maxSalary) &&
                <div className='flex'><BanknotesIcon className="h-6 w-6 mr-1" /> { data.minSalary }{data.maxSalary ? '-' : ''}{data.maxSalary} ₽</div>
            }
        </div>
        <p className='mt-8'>{ data.description }</p>
        <button
            onClick={sendVacancyInChat}
            className="btn btn-primary text-white mt-8"
            type='button'>
            Отправить вакансию в чат
        </button>
    </div>;
}

export default Vacancy;
