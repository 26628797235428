import React, { useCallback, useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import { useVacancies } from '../queries/vacancies';
import ReactPaginate from 'react-paginate';
import { useTelegram } from '../utils/useTelegram';
import { useNavigate, useSearchParams } from 'react-router-dom';
import plural from 'plural-ru';
import { postBotSubscribe } from '../queries/bot';
import { useMutation } from 'react-query';

const ITEMS_PER_PAGE = 18;

const Vacancies = () => {
    const { tg, showMainButton, hideMainButton, hideBackButton, userId } = useTelegram();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [count, setCount] = useState(0);
    const searchTitle = searchParams.get('title');
    const [search, setSearch] = useState(searchTitle || '');
    const page = searchParams.get('page');
    const { data, isFetching } = useVacancies({
        filter: JSON.stringify({
            title: searchTitle
        }),
        pagination: JSON.stringify({
            offset: Number(page || 0),
            limit: ITEMS_PER_PAGE
        }),
        relations: ['cities']
    }, {
        onSuccess: data => {
            setCount(pathOr(0, ['_meta', 'count'], data));
        }
    });
    const postBotSubscribeMutation = useMutation(postBotSubscribe, {
        onSuccess: () => {
            tg.showAlert(`Вы успешно подписались на вакансии по запросу "${searchTitle}"`);
        },
        onError: () => {
            tg.showAlert(`Не удалось подписаться на вакансии по запросу "${searchTitle}"`);
        }
    });

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'ПОДПИСАТЬСЯ НА ВАКАНСИИ',
            color: '#249EEB'
        });
    }, []);

    const onMainButtonClicked = useCallback(() => {
        subscribe();
    }, [searchTitle]);

    useEffect(() => {
        hideBackButton();
        tg.onEvent('mainButtonClicked', onMainButtonClicked);
        return () => tg.offEvent('mainButtonClicked', onMainButtonClicked);
    }, [onMainButtonClicked]);

    useEffect(() => {
        if (searchTitle) {
            showMainButton();
        } else {
            hideMainButton();
        }
    }, [searchTitle]);

    const onSearch = () => {
        if (search !== (searchTitle || '')) {
            setCount(0);
            setSearchParams({
                ...(search ? { title: search } : {}),
                page: 0
            });
        }
    }

    const vacancies = pathOr([], ['items'], data);
    const pageCount = !count ? 0 : Math.ceil(count / ITEMS_PER_PAGE);

    const handlePageClick = ({ selected }) => {
        setSearchParams({
            ...(searchTitle ? { title: searchTitle } : {}),
            page: selected
        });
    }

    const subscribe = () => {
        postBotSubscribeMutation.mutate({
            userId,
            searchQuery: searchTitle
        });
    }

    return <div className='container mx-auto p-4'>
        <div className='flex'>
            <input
                className="input input-bordered w-full"
                type='text'
                value={search}
                onChange={e => setSearch(e.target.value || '')}
                placeholder='Название должности' />
            <button
                className="btn btn-md ml-4"
                type='button'
                onClick={onSearch}>
                Поиск
            </button>
        </div>
        <div className='text-sm mt-1'>Найдено { plural(count, '%d вакансия', '%d вакансии', '%d вакансий') }</div>
        <div className='mt-5 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-4'>
            { vacancies.map(vacancy =>
                <div className="card shadow-xl" key={`vacancy-${vacancy.id}`}>
                    <div className="card-body justify-between">
                        <div>
                            <h2 className="card-title">{ vacancy.title }</h2>
                            { !!pathOr([], ['_relations', 'cities'], vacancy).length &&
                                <p>{ pathOr([], ['_relations', 'cities'], vacancy).reduce((res, cur) => res ? `${res}, ${cur.name}` : cur.name, '') }</p>
                            }
                        </div>
                        <div className="card-actions justify-end mt-4">
                            <button
                                className="btn btn-primary text-white"
                                type='button'
                                onClick={() => navigate(`/vacancies/${vacancy.id}/apply?backbutton=1`)}>
                                Откликнуться
                            </button>
                            <button
                                className="btn btn-primary text-white"
                                type='button'
                                onClick={() => navigate(`/vacancies/${vacancy.id}?backbutton=1`)}>
                                Подробнее
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
        { isFetching &&
            <div className='flex justify-center'>
                <span className="loading loading-dots loading-lg"></span>
            </div>
        }
        { !!pageCount && pageCount > 1 &&
            <div className='flex justify-center m-4'>
                <ReactPaginate
                    className='join'
                    pageLinkClassName='join-item btn'
                    previousLinkClassName='join-item btn'
                    nextLinkClassName='join-item btn'
                    disabledLinkClassName='btn-disabled'
                    breakLinkClassName='join-item btn disabled'
                    activeLinkClassName='btn-active'
                    forcePage={page ? Number(page) : 0}
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageRangeDisplayed={3}
                    previousLabel="<"
                />
            </div>
        }
    </div>;
}

export default Vacancies;
