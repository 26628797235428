import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);

export const n8nUrl = ' https://n8n.demo.experium.net/webhook/vacancy-bot';

export const BASE_URL = '/api';

export const VACANCIES = construct('/public/vacancy(/:id)');
export const FILE = construct('/landing/public/file/:vacancy(/:id)');
export const VACANCY_APPLICANT = construct('/public/vacancy/:id/applicant');

export const SUBSCRIBE = construct('/subscribe');
export const UNSUBSCRIBE = construct('/unsubscribe');
export const SEND = construct('/send');
