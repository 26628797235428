import { path } from "ramda";

const tg = window.Telegram.WebApp;

export const useTelegram = () => {
    const showMainButton = () => {
        if (!tg.MainButton.isVisible) {
            tg.MainButton.show();
        }
    }

    const hideMainButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide();
        }
    }
    const showBackButton = () => {
        if (!tg.BackButton.isVisible) {
            tg.BackButton.show();
        }
    }

    const hideBackButton = () => {
        if (tg.BackButton.isVisible) {
            tg.BackButton.hide();
        }
    }

    return {
        tg,
        user: path(['initDataUnsafe', 'user'], tg),
        userId: path(['initDataUnsafe', 'user', 'id'], tg),
        showMainButton,
        hideMainButton,
        showBackButton,
        hideBackButton,
        queryId: path(['initDataUnsafe', 'query_id'], tg)
    };
}
