import { useEffect } from 'react';
import { useMutation } from "react-query";
import { postBotUnsubscribe } from "../queries/bot";
import { useTelegram } from "../utils/useTelegram";

const Unsubscribe = () => {
    const { tg, userId } = useTelegram();
    const postBotUnsubscribeMutation = useMutation(postBotUnsubscribe, {
        onSuccess: () => {
            tg.showAlert(`Вы успешно отписались от рассылки вакансий`, () => tg.close());
        },
        onError: () => {
            tg.showAlert(`Не удалось отписаться от рассылки вакансий`, () => tg.close());
        }
    });

    useEffect(() => {
        postBotUnsubscribeMutation.mutate({ userId });
    }, []);

    return null;
}

export default Unsubscribe;
