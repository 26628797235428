import axios from "axios";

import * as urls from '../constants/urls';

const axiosInstance = axios.create({
    baseURL: urls.n8nUrl
});

export const postBotSubscribe = async (payload) => {
    const { data } = await axiosInstance.post(urls.SUBSCRIBE.stringify(), payload);
    return data;
};

export const postBotUnsubscribe = async (payload) => {
    const { data } = await axiosInstance.post(urls.UNSUBSCRIBE.stringify(), payload);
    return data;
};

export const postBotSend = async (payload) => {
    const { data } = await axiosInstance.post(urls.SEND.stringify(), payload);
    return data;
};
