import React from 'react';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';

const VacancyApplySuccess = () => {
    return <div className='container mx-auto p-4 h-screen'>
        <div className='grid place-content-center h-full'>
            <CheckBadgeIcon className="h-20 w-20 justify-self-center" />
            <div className='text-2xl'>Отклик успешно отправлен!</div>
        </div>
    </div>;
}

export default VacancyApplySuccess;
